import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AsYouType } from 'libphonenumber-js';
import FlagIcon from '../../components/FlagIcon.js'
import { compose } from 'recompose';
import { withStyles, FormControl } from '@material-ui/core';

const styles = theme => ({
 
  title: {
    margin: '0 auto',
  },
  notchedOutline: {
    borderWidth: "0px",
    borderColor: "transparent !important"
  },
});

const PhoneNumber = ({ input, name, meta, country, flagSize, classes, readOnly, ...rest }) => {

  const asYouType = new AsYouType(country);
  const updatedValue = asYouType.input(input.value);
  const updatedCountry = (asYouType.getNumber() && asYouType.getNumber().country) || country;

  return (
    <FormControl style={{ width: '90%' }} >

      <TextField
        {...rest}
        name={name}
        error={meta.error && meta.touched}
        helperText={meta.touched ? meta.error : undefined}
        value={!updatedValue ? '+33' : updatedValue}
        onChange={input.onChange}
        onBlur={() => input.onBlur()}
        margin="dense"
        className={classes.textField}
        variant="outlined"
        InputProps={{
          readOnly: readOnly,
          classes: {
            notchedOutline: readOnly && classes.notchedOutline
          },
          startAdornment: (
            <InputAdornment position="start">
              <FlagIcon code={updatedCountry.toLowerCase()} size={flagSize} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
export const enhance = compose(withStyles(styles))
export default enhance(PhoneNumber);
