import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { compose, lifecycle } from 'recompose';
import Grid from '@material-ui/core/Grid';
import EmailsList from './EmailsList';
import EmailContent from './EmailContent';

const styles = theme => ({
  
    root: {
        width: '80%',
        minWidth:200,
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        backgroundColor: '#ececec',

    },
    body: {
        fontSize: 18,
        fontWeight: 'bold',
        marginLeft: '2%'
    },
    container: {
        marginTop: theme.spacing.unit * 1
    }
});

const Component = ({ classes, handleEmail, language, code, editMode, emailSettings, Email }) => {
    return (

        <Grid item xs={12}>
            <Grid container >
                <Grid item xs={2}>
                    <Grid container>
                        <Paper className={classes.root} elevation={1}>
                            <Grid item xs={12}>
                                <Grid container className={classes.container}>
                                    <Typography className={classes.body}>LIST</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.container} >
                                <Grid item xs={12}>
                                    <EmailsList handleEmail={handleEmail} editMode={editMode} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <EmailContent editMode={editMode} language={language} code={code} emailSettings={emailSettings} Email={Email} />
                </Grid>
            </Grid>
        </Grid>
    )
}
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.getEmailSettings()
    },
},
);
export const enhance = compose(
    withStyles(styles),
    loadComponents)
export default enhance(Component)

