import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { withStateHandlers, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import Settings from '@material-ui/icons/Settings';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import SystemSettings from './SystemSettings';
import ConventionSettings from './ConventionSettings';
import EmailSettings from './EmailSettings';
import { Form } from 'react-final-form';
import { getEmailContent } from '../../utils/models';
import { emailsList } from "../../utils/select";
import FormErrors from '../../widgets/FormErrors';

const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,

    },
    editButton: {
        textTransform: 'none',
        backgroundColor: "#4bacc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid rgba(45,56,130,1)',
    },
    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',

    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',


    },
    appBar: {
        marginTop: '2%',
        width: '100%'
    }
})
const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}


const Component = ({ classes, toggleSettingsTabs, tabValue, editMode, actions, systemeSettings,
    conventionSettings, toggleTextField, rowsChanges, emailSettings, handleEmail, language, code, systemErrors }) => {
    const onSubmit = (values) => {
        actions.UpdateEmailSettings({
            ...values,
        })

    }
    let Email = getEmailContent(code, language, emailSettings)
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                ...Email,
            }}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid item xs={10}>

                        <Grid container className={classes.header}>
                            <Grid item xs={2}>
                                <Grid container justify="flex-start" alignItems="flex-end"  >
                                    <Settings className={classes.icon} />
                                    <Spacer size="MEDIUM" />
                                    <Title text={<FormattedMessage id="user.settings" />} />
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                {!editMode ?
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Grid container justify="flex-start" alignItems="flex-end">
                                                <Button onClick={() => { actions.toggleEditMode(true); toggleTextField([]) }} className={classes.editButton} >
                                                    edit
                                                    </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid> :
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container justify="flex-start" alignItems="flex-end">
                                                <Button onClick={() => {
                                                    actions.toggleEditMode(false);
                                                    tabValue === 0 && actions.setSystemErrors()
                                                    tabValue === 2 && form.reset()
                                                }} className={classes.button}  >
                                                    cancel
                                                    </Button>
                                                <Spacer size="MEDIUM" />
                                                {tabValue !== 2 ?
                                                    <Button
                                                        onClick={() => {
                                                            tabValue === 0 ? actions.UpdateSystemeSettings(rowsChanges) :
                                                                actions.UpdateConventionSettings(rowsChanges)
                                                        }}
                                                        className={classes.editButton} >
                                                        save
                                        </Button> :
                                                    <Button disabled={(!values.subject||values.template === "<p><br></p>") ? true : false} className={classes.editButton} type="submit">
                                                        save
                                        </Button>}
                                            </Grid>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                            <Grid />
                        </Grid>
                    </Grid>
                    <FormErrors isError={systemErrors} />

                    <Grid item xs={10}>
                        <Grid container justify="center">
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                                <AppBar className={classes.appBar} position="static" color="default">
                                    <Tabs
                                        value={tabValue}
                                        indicatorColor="primary"
                                    >
                                        <Tab disabled={editMode && tabValue !== 0} onClick={() => toggleSettingsTabs(0)} classes={{ root: classes.tabRoot }} label="System" />
                                        <Tab disabled={editMode && tabValue !== 1} onClick={() => toggleSettingsTabs(1)} classes={{ root: classes.tabRoot }} label="Conventions" />
                                        <Tab disabled={editMode && tabValue !== 2} onClick={() => toggleSettingsTabs(2)} classes={{ root: classes.tabRoot }} label="Emails" />
                                    </Tabs>
                                </AppBar>
                                {tabValue === 0 && (
                                    <TabContainer>
                                        <SystemSettings editMode={editMode} actions={actions} systemeSettings={systemeSettings} toggleTextField={toggleTextField} rowsChanges={rowsChanges} systemErrors={systemErrors} />
                                    </TabContainer>
                                )}
                                {tabValue === 1 && (
                                    <TabContainer>
                                        <ConventionSettings editMode={editMode} actions={actions} conventionSettings={conventionSettings} toggleTextField={toggleTextField} rowsChanges={rowsChanges} />
                                    </TabContainer>
                                )}
                                {tabValue === 2 && (
                                    <TabContainer>
                                        <EmailSettings Email={Email} editMode={editMode} actions={actions} emailSettings={emailSettings} handleEmail={handleEmail} />
                                    </TabContainer>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            )}
        />)
}
const withSettingsTabs = withStateHandlers(() => ({ tabValue: 0 }), {
    toggleSettingsTabs: () => value => ({ tabValue: value }),
});

const withTextField = withStateHandlers(() => ({ rowsChanges: [] }), {
    toggleTextField: () => value => ({ rowsChanges: value }),
});

const withEmail = withStateHandlers(() => ({ language: emailsList[0].language, code: emailsList[0].value }), {
    handleEmail: () => (value1, value2) => ({ code: value1, language: value2 }),
})

const loadComponents = lifecycle({
    componentWillUnmount() {
        const { actions } = this.props
        actions.toggleEditMode(false);
        actions.setSystemErrors()

    },

},
);

const enhace = compose(
    withStyles(styles),
    withSettingsTabs,
    withTextField,
    withEmail,
    loadComponents)
export default enhace(Component)