import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { withStateHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import BarChart from '@material-ui/icons/BarChart';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import Accounts from './Accounts';
import Charts from './Chart\'s';
import Button from '@material-ui/core/Button'


const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,

    },


    button: {
        backgroundColor: '#d9d9d9',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',
    },
    appBar: {
        marginTop: '2%',
        width: '100%'
    }

})
const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}


const Component = ({ classes, toggleSettingsTabs, tabValue, actions, openedAccounts, kpiStatistics }) => {


    return (
        <Grid container >
            <Grid item xs={10}>
                <Grid container className={classes.header}>
                    <Grid item xs={2}>
                        <Grid container justify="flex-start"  alignItems="flex-end"  >
                            <BarChart className={classes.icon} />
                            <Spacer size="MEDIUM" />
                            <Title text={<FormattedMessage id="user.reports" />} />
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Button disabled={true} className={classes.button}  >
                                        export CSV
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid />
                </Grid>
            </Grid>

            <Grid item xs={10}>
                <Grid container justify="center">
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <AppBar className={classes.appBar} position="static" color="default">
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                            >
                                <Tab onClick={() => toggleSettingsTabs(0)} classes={{ root: classes.tabRoot }} label="Open accounts" />
                                <Tab onClick={() => toggleSettingsTabs(1)} classes={{ root: classes.tabRoot }} label="KPIs" />
                            </Tabs>
                        </AppBar>
                        {tabValue === 0 && (
                            <TabContainer>
                                <Accounts actions={actions} openedAccounts={openedAccounts} />
                            </TabContainer>
                        )}
                        {tabValue === 1 && (
                            <TabContainer>
                                <Charts actions={actions} kpiStatistics={kpiStatistics} />
                            </TabContainer>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
}
const withSettingsTabs = withStateHandlers(() => ({ tabValue: 0 }), {
    toggleSettingsTabs: () => value => ({ tabValue: value }),
});

const enhace = compose(
    withStyles(styles),
    withSettingsTabs,
)
export default enhace(Component)