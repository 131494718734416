import React from 'react';
import { compose, lifecycle, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Charts from './Charts';
import Filters from './Filters';
import { getSubYear, formatDate } from '../../../utils/date';

const styles = (theme) => ({
    container: {
        marginTop: theme.spacing.unit * 3
    }
})

const Component = ({ classes, kpiStatistics, actions, unitValue, toggleUnitFilter, toggleAccountFilter, accountValue }) => {
    return (
        <Grid container className={classes.container}>
            <Grid item xs={3}>
                <Filters toggleUnitFilter={toggleUnitFilter} toggleAccountFilter={toggleAccountFilter} unitValue={unitValue} accountValue={accountValue} />
            </Grid>
            <Grid item xs={9}>
                <Charts kpiStatistics={kpiStatistics} actions={actions} unitValue={unitValue} accountValue={accountValue} />
            </Grid>
        </Grid>
    )
}

const withAccountsFilter = withStateHandlers(() => ({ accountValue: 'all' }), {
    toggleAccountFilter: () => (value) => ({ accountValue: value }),
})
const withUnitFilter = withStateHandlers(() => ({ unitValue: 'failed' }), {
    toggleUnitFilter: () => (value) => ({ unitValue: value }),
})

const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, unitValue } = this.props
        actions.getKpiStatistics(getSubYear(new Date(), 5), formatDate(new Date()), unitValue)
    },
    componentWillReceiveProps(nextProps) {
        const { actions, unitValue } = this.props
        if(nextProps.unitValue!==unitValue){
            actions.getKpiStatistics(getSubYear(new Date(), 5), formatDate(new Date()), nextProps.unitValue)

        }
    },
},
);
const enhance = compose(
    withStyles(styles),
    withAccountsFilter,
    withUnitFilter,
    loadComponents)
export default enhance(Component)