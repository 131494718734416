import { SET__SYSTEME_SETTINGS, SET_CONVENTION_SETTINGES, TOGGLE_EDIT_MODE, SET_EMAIL_SETTINGS, SET_SYSTEM_ERRORS } from "../actions/settings";


export default (state = {}, action) => {
    switch (action.type) {
        case SET__SYSTEME_SETTINGS:
            return { ...state, systemeSettings: action.data }
        case SET_CONVENTION_SETTINGES:
            return { ...state, conventionSettings: action.data }
        case SET_EMAIL_SETTINGS: {
            return { ...state, emailSettings: action.data }
        }
        case TOGGLE_EDIT_MODE: {
            return { ...state, editMode: action.value }
        }
        case SET_SYSTEM_ERRORS: {
            return { ...state, systemError: action.value }
        }
        default:
            return state;
    }
};
