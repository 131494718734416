import React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { getIsLoggedInStatus } from '../../selectors/authentication';
import Authentication from '../../pages/Authentication';
import withRoutes from '../../hoc/routes';
import { loadAuthenticationState } from '../../utils/sessionStorage';

const Auth = ({ isLoggedIn,children }) => {

//  if (!isLoggedIn && loadAuthenticationState() === null ) return <Authentication />;
return children;
};



const mapStateToProps = createStructuredSelector({
  isLoggedIn: getIsLoggedInStatus
});

const enhance = compose(
  connect(mapStateToProps),
  withRoutes,
);

export default enhance(Auth);
