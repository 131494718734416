import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit * 3,
  },
  colorSwitchBase: {

    color: 'grey',
    '&$colorChecked': {
      color: '#28558a',
      '& + $colorBar': {
        backgroundColor: '#28558a',
      },
    },
  },
  colorBar: {},
  colorChecked: {},


});
const SwitchForm = ({
  input: { onChange, name, checked = true, ...restInput },
  classes,
  label,
  test,
  ...rest
}) => (

    <FormControlLabel
      control={
        <Switch
          {...rest}
          checked={checked}
          onChange={onChange}
          name={name}
          inputProps={restInput}
          className={classes.textField}
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            bar:classes.colorBar ,
          }}
          disabled={test}

        />
      }
      label={<label style={{ color: '#4A6D96', fontSize: '1rem' }}>{label}</label>}
    />
  );

SwitchForm.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  classes: PropTypes.object,
};
export default withStyles(styles)(SwitchForm);
