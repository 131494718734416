import { compose, filter, map, reduce, values, find, propEq, pick, sum, innerJoin, move, findIndex, sortWith, ascend, join, uniq, groupBy, keys, sort } from 'ramda';
import { status, level, type } from './select'
import { contains } from 'ramda'
import { loadDecodedToken } from './sessionStorage';
import { formatKpiDate } from './date';
import keycloak from '../Keycloak'
export const customizeMultiSelectDisplay = (selected, families) => {
    compose(reduce((prev, curr) => prev.concat(curr), []), map(element => filter(ele => element === ele.id)(families)))(selected)
}

export const getLabel = (element, object) =>
    element &&
    compose(values, pick(['label']), find(propEq('value', element)))(object)

export const getStatusByUser = (element) => {

    switch (element) {
        case 'SUSPENDED':
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['SUSPENDED', 'PENDING']
            )
        case 'ACTIVE': {
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['ACTIVE', 'SUSPENDED']
            )
        }
        case 'PENDING': {
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['PENDING', 'SUSPENDED']
            )
        }
        case 'BLOCKED': {
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['BLOCKED', 'SUSPENDED']
            )
        }
        case 'EXPIRED': {
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['EXPIRED', 'SUSPENDED']
            )
        }
        case 'CLOSED': {
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['CLOSED']
            )
        }
        case 'SATURATED': {
            return innerJoin(
                (record, id) => record.value === id,
                status,
                ['SATURATED', 'SUSPENDED']
            )
        }
        default:
    }

}
const getIndex = (element) => findIndex(propEq('value', element))(getStatusByUser(element))
export const sortStatusList = (element) => move(getIndex(element), 0, getStatusByUser(element))
export const getFamilyName = (families, selected) => families && compose(map(element => element[0] && element[0].name), map(ele => filter((el) => ele === el.id)(families)))(selected)
export const sortFamiliesSelected = (selected) => selected && compose(sortWith([ascend(map(ele => ele))]))(selected)
export const formatFamilies = (selected, families) => compose(reduce((prev, curr) => prev.concat(curr), []), map(element => filter(ele => element === ele.id)(families))
)(selected)

export const isInRole = (role) => contains(role, keycloak.realmAccess.roles);

export const getEmailContent = (code, language, list) => list && find(element => (element.code === code && element.language.toUpperCase() === language.toUpperCase()))(list)

export const getElementCount = (list, type, status) => {
    if (list !== undefined) {
        if (type === '' && status === '') {
            return sum(reduce((acc, element) => [...acc, element.count], [])(list['accountStatusStats']))
        }
        else {
            let test = compose(find(propEq(`${type}Status`, status)))(list[`${type}StatusStats`])
            if (test !== undefined) {
                return (
                    compose(values, pick(['count']))(test)
                )
            }
            else return 0

        }
    }

}

export const fullname = compose(
    join(' '),
    values,
    pick(['lastName', 'firstName']),
    x => x || {},
);

export const getPercentage = (count, total) => Math.round(count * 100 / total)
export const isSerieOrLevel = (element, list) => contains(element, map(element => element.value)(list));

export const getDataSets = (data, filter, unit) => {
    if (data) {
        const byDate = groupBy(function (elem) {
            return elem.date;
        });
        const bySerie = groupBy(function (elem) {
            return elem.serie;
        });

        const colors = ['#a9b2be', '#889dbe', '#4a6d96', '#0d3d77', '#28558a']
        const series = compose(keys, bySerie)(data)
        const sortedSeries = filter === 'type' ?
            sort(diff(typeWeight), series) :
            filter === 'level' ? sort(diff(levelWeight), series) :
                series;


        const dates = compose(values, byDate)(data)
        return sortedSeries.map((serie, index) => {
            const data = map((ele) => {
                const count = compose(find(propEq('serie', serie)))(ele)
                return count && (unit === 'failed' || unit === 'blocked') ?
                    Math.round(count.count * 100 / count.total) :
                    count && (unit === 'conventions' || unit === 'connections') ?
                        count.count
                        : null;
            })(dates)
            return {
                label: filter === 'all' ? 'All' : filter === 'level' ? isSerieOrLevel(serie, level) ? getLabel(serie, level) : "" : isSerieOrLevel(serie, type) ? getLabel(serie, type) : "",
                data: data,
                backgroundColor: filter === 'all' ? colors[4] : colors[index]
            }
        });
    }
    return []
}
const typeWeight = {
    'employee': 1,
    'temp': 2,
    'client': 3,
    'supplier': 4
}
const levelWeight = {
    'LEVEL_1': 1,
    'LEVEL_2': 2,
    'LEVEL_3': 3,
}
export const diff = (tableOfwieght) => function (a, b) { return tableOfwieght[a] - tableOfwieght[b]; };

export const getDatasetsLabel = (data) => data && compose(uniq, map(element => formatKpiDate(element.date)))(data)



export const itSortExist = (type, direction, sort) => find(element => element.type === type && element.direction === direction)(sort)

export const onSortClick = (type, direction, action) => {
    action([{ 'type': type, 'direction': direction }])

}

export const isInRoleBis=(role, object) => contains(role, object);
