import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Slide } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { formatFamilies } from '../utils/models';
const Transition = props => {
  return <Slide direction="up" {...props} />;
};
const ConfirmClosing = ({ open, onClose, actions, toggleEditMode, values, selected, user, families }) => {

  const newValues = {
    ...values,
    conventionFamilies: selected ? formatFamilies(selected, families) : values.conventionFamilies,
    createdDate: user && user.createdDate,
    conventionStartDate: user && user.conventionStartDate,
    lastActivityDate: user && user.lastActivityDate,
    conventionStatus: user && user.status === 'CLOSED' ? user.conventionStatus : values.conventionStatus
  }
  return (
    <Dialog TransitionComponent={Transition}
      open={open}
      fullWidth={true}
    >
      <DialogTitle>
        <Grid container justify="center">
          'Closing account'
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center">
          <DialogContentText>
            Are you sure you want to close this account
      </DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button onClick={() => onClose(false)} color="primary" >
            No
      </Button>
          <Button
            type='submit'
            color="primary"
            onClick={() => { actions.closeAccount(newValues); toggleEditMode(false); onClose(false) }} >
            Yes
      </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
};



export default ConfirmClosing;
