import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../../../images/logoHIAdatafab.png';
import Menu from './Menu';
import { loadAuthenticationState } from '../../../utils/sessionStorage';
import { Link } from 'react-router-dom';
import withRoutes from '../../../hoc/routes';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    image: {
        height: '100px',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            height: '100%'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '70px',
            height: '100%'

        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '100px',
            height: '100%'
        },
    }
});
const Component = ({ classes, isLoggedIn, routes }) => {

    return (
        <AppBar style={{ backgroundColor: "rgba(45,56,130,1)" }} position="relative">
            <Toolbar>
                <Grid item xs={10}>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Grid container >
                                <Typography component={Link} to={routes.getPathByName('Home')} >
                                    <img src={logo} width="100px" alt="logo HIA" />
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            <Grid item xs={10}>
                                <Grid container justify="flex-start" alignItems="center">
                                    <Menu />
                                </Grid>
                            </Grid>}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>

    )
}

export const enhance = compose(
    withStyles(styles),
    withRoutes
);

export default enhance(Component);
