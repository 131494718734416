import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Content from '../../widgets/Content';
import LoginForm from '../../components/LoginForm';
import * as authenticationActions from '../../actions/authentication'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getIsLoggedInStatus, getAuthenticationError } from '../../selectors/authentication';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

const styles = (theme) => ({

})
const Page = ({ classes, actions,authenticationError }) => {
    return (
        <Grid container>
            <Content>
                <LoginForm actions={actions} authenticationError={authenticationError} />
            </Content>
        </Grid>)
}

const actions = {
    validateLogin: authenticationActions.validateLogin,
    logout: authenticationActions.logout,
    setErrors:authenticationActions.setErrors

};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    isLoggedIn: getIsLoggedInStatus,
    authenticationError:getAuthenticationError
})
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.logout();
    },
    componentWillReceiveProps(nextProps) {
        const { isLoggedIn, history } = this.props
        if (nextProps.isLoggedIn !== isLoggedIn) {
            history.push('/home')
        }
    },

},
);
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    loadComponents
)
export default enhance(Page)