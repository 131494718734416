import React from 'react';
import Grid from '@material-ui/core/Grid';
import Content from '../../widgets/Content'
import Reports from '../../components/Reports';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as reportsAction from '../../actions/reports';
import { bindActionCreators } from 'redux';
import { getSortedAccounts, getKpiStats } from '../../selectors/reports';


const Page = ({ actions, openedAccounts, kpiStatistics }) => {

    return (
        <Grid container>

            <Content>
                <Reports actions={actions} openedAccounts={openedAccounts} kpiStatistics={kpiStatistics} />
            </Content>
        </Grid>

    )

}
const actions = {
    getOpenedAccounts: reportsAction.getOpenedAccounts,
    getKpiStatistics: reportsAction.getKpiStatistics
};


const mapStateToProps = createStructuredSelector({
    openedAccounts: getSortedAccounts,
    kpiStatistics: getKpiStats
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
export const enhance = compose(connect(mapStateToProps, mapDispatchToProps))
export default enhance(Page)
