import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';

const Styles = {
  content: {
   // height:"80%"
   margin:'0 auto'
  }
};

const Content = ({ classes, children }) => (
  <Grid container className={classes.content} justify="center" >
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);


const enhance = compose(withStyles(Styles));
export default enhance(Content);
