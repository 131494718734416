import React from 'react';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Home from '@material-ui/icons/Home';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import SearchBar from 'material-ui-search-bar'
import { Link } from 'react-router-dom';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import { FormattedMessage } from 'react-intl';
import { alphabet } from '../../utils/select'
import Facets from './Facets';
import withRoutes from '../../hoc/routes';
import UsersTable from './UsersTable';
import Kpi from './Kpi';


const styles = (theme) => ({

    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,

    },

    content: {
        marginLeft: theme.spacing.unit * 2
    },
    button: {
        backgroundColor: '#fff',
        color: 'black',
        fontSize: '16px',
        width: '90%',
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',
        height: 47

    },
    secondButton: {
        backgroundColor: '#d9d9d9',
        color: 'black',
        fontSize: '16px',
        width: '90%',
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',
        height: 47,
        '&:hover': {
            backgroundColor: '#d9d9d9',

        }

    },
    bigIndicator: {
        height: 0,
    },
    paper: {
        background: "none",
        display: "flex",
        border: '1px solid rgba(45,56,130,1)',
        height: 47,
        width: '100%',
        cursor: 'pointer'

    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: 15,
        color: "black",
        marginLeft: "2px",


        '&:hover': {
            color: '#28558a',
            opacity: 1,
        },
        '&$tabSelected': {
            color: 'blue',
        },
        '&:focus': {
            color: '#28558a',
        },
    },
    tabSelected: {
        textTransform: 'initial',
        fontSize: 15,
        color: "#28558a",
        marginLeft: "2px",
        textDecoration: 'underline',


        '&:hover': {
            color: '#28558a',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#28558a',
        },
        '&:focus': {
            color: '#28558a',
        },
    },

})

const Component = ({ classes, toggleMenu, anchor, menu, routes, users, pageInformation, actions, rowsPerPage, toggleRows,
    togglePage, page, toggleNameSort, nameSort, count, alphabetIndex, isKPIShowed, toggleKpi, toggleFilter,
    dashboardStats, toggleSearchTerm, toggleStartWith, searchType, searchLevel, toggleFacetFilter, toggleDateFilter }) => {


    return (
        <Grid container >
            <Grid item xs={10}>
                <Grid container className={classes.header}>
                    <Grid item xs={2}>
                        <Grid container justify="flex-start"  alignItems="flex-end"  >
                            <Home className={classes.icon} />
                            <Spacer size="MEDIUM" />
                            <Title text={<FormattedMessage id="user.dashboard" />} />
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <IconButton onClick={() => toggleKpi(!isKPIShowed)}>
                                        {isKPIShowed ? <ArrowDropUp style={{ color: "black", fontSize: 30 }} /> :
                                            <ArrowDropDown style={{ color: "black", fontSize: 30 }} />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid />
                </Grid>
            </Grid>
            {isKPIShowed && <Kpi dashboardStats={dashboardStats} />}
      
            <Grid container className={classes.content}  >
                <Grid item xs={10}>
                    <Grid container justify="center" alignItems="center" >
                        <Grid item xs={3}>
                            <Grid container justify="flex-start">
                                <SearchBar
                                    onChange={event => toggleSearchTerm(event)}
                                    onCancelSearch={() => toggleSearchTerm('')}
                                    style={{
                                        border: '1px solid #4a7ebb',
                                        width: '80%'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justify="flex-start">
                                <Button className={classes.button}
                                    component={Link}
                                    to={routes.getPathByName('AddUser')}>
                                    + add user
                        </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justify="flex-start">
                                <Button disabled={true} className={classes.secondButton}>import users</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justify="flex-start">
                                <Button disabled={true} className={classes.secondButton}>export csv</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container justify="flex-end">
                                <Paper className={classes.paper} >
                                    <Grid container alignItems="center" justify="center" style={{ margin: 0 }}>
                                        <Typography onClick={() => { toggleStartWith('', -1) }}
                                            classes={{ root: alphabetIndex === -1 ? classes.tabSelected : classes.tabRoot }}
                                            style={{ marginRight: "5px" }}>
                                            All
                                 </Typography>
                                        {alphabet.map((element, index) => <Typography key={element.value}
                                            classes={{ root: index === alphabetIndex ? classes.tabSelected : classes.tabRoot }}
                                            onClick={() => { toggleStartWith(element.value, index) }}>
                                            {element.label}</Typography>)}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Grid container  >
                        <Grid item xs={3}>
                            <Facets actions={actions} count={count} toggleFacetFilter={toggleFacetFilter} />
                        </Grid>
                        <Grid item xs={9}>
                            <UsersTable users={users} toggleMenu={toggleMenu} anchor={anchor} menu={menu}
                                pageInformation={pageInformation} rowsPerPage={rowsPerPage}
                                page={page} togglePage={togglePage} toggleRows={toggleRows} toggleNameSort={toggleNameSort}
                                nameSort={nameSort} toggleFilter={toggleFilter} searchType={searchType}
                                searchLevel={searchLevel} toggleDateFilter={toggleDateFilter} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </Grid >
    )
}
const withColumnMenu = withStateHandlers(() => ({ anchor: undefined, menu: undefined }), {
    toggleMenu: () => (value1, value2) => ({ anchor: value1, menu: value2 }),
});
const withRows = withStateHandlers(() => ({ rowsPerPage: 10 }), {
    toggleRows: () => event => ({ rowsPerPage: event.target.value }),
});
const withPage = withStateHandlers(() => ({ page: 0 }), {
    togglePage: () => (event, page) => ({ page: page }),
});
const withNameSort = withStateHandlers(() => ({ nameSort: '' }), {
    toggleNameSort: () => value => ({ nameSort: value }),
});
const withKPI = withStateHandlers(() => ({ isKPIShowed: false }), {
    toggleKpi: () => value => ({ isKPIShowed: value }),
});
const withSearchTerm = withStateHandlers(() => ({ term: '' }), {
    toggleSearchTerm: () => value => ({ term: value }),
});
const withStartWith = withStateHandlers(() => ({ startWith: '', alphabetIndex: -1 }), {
    toggleStartWith: () => (value1, value2) => ({ startWith: value1, alphabetIndex: value2 }),
})
const withFilter = withStateHandlers(() => ({ searchType: '', searchLevel: '' }), {
    toggleFilter: () => (value1, value2) => ({ searchType: value1, searchLevel: value2 }),
})
const withFacetFilter = withStateHandlers(() => ({ accountStatus: '', conventionStatus: '' }), {
    toggleFacetFilter: () => (value1, value2) => ({ accountStatus: value1, conventionStatus: value2 }),
});
const withDateFilter = withStateHandlers(() => ({ dateFrom: '', dateTo: '', withoutActivity: false }), {
    toggleDateFilter: () => (value1, value2, value3) => ({ dateFrom: value1, dateTo: value2, withoutActivity: value3 }),
});
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, term, startWith, searchType, searchLevel, dateTo, dateFrom, nameSort, conventionStatus, accountStatus, withoutActivity, page, rowsPerPage } = this.props
        actions.searchUser(term, startWith, searchType, searchLevel, dateFrom, dateTo, withoutActivity, conventionStatus, accountStatus, nameSort, page, rowsPerPage)
    },
    componentWillReceiveProps(nextProps) {
        const { actions, term, startWith, searchType, searchLevel, dateTo, dateFrom, nameSort, conventionStatus, accountStatus, withoutActivity, page, rowsPerPage } = this.props
        if (nextProps.term !== term || nextProps.startWith !== startWith || nextProps.searchType !== searchType ||
            nextProps.searchLevel !== searchLevel || (nextProps.dateTo !== dateTo) ||
            (nextProps.dateFrom !== dateFrom) || nextProps.nameSort !== nameSort || nextProps.withoutActivity !== withoutActivity || nextProps.rowsPerPage !== rowsPerPage || nextProps.page !== page || nextProps.conventionStatus !== conventionStatus || nextProps.accountStatus !== accountStatus) {


            if (nextProps.rowsPerPage * nextProps.pageInformation.number > nextProps.pageInformation.totalElements) {
                actions.searchUser(nextProps.term, nextProps.startWith, nextProps.searchType, nextProps.searchLevel, nextProps.dateFrom,
                    nextProps.dateTo, nextProps.withoutActivity, nextProps.conventionStatus, nextProps.accountStatus, nextProps.nameSort, Math.ceil(nextProps.pageInformation.totalElements / nextProps.rowsPerPage) - 1, nextProps.rowsPerPage)
            }
            else {
                actions.searchUser(nextProps.term, nextProps.startWith, nextProps.searchType, nextProps.searchLevel, nextProps.dateFrom,
                    nextProps.dateTo, nextProps.withoutActivity, nextProps.conventionStatus, nextProps.accountStatus, nextProps.nameSort, nextProps.page, nextProps.rowsPerPage)
            }


        }
    },

},
);
export const enhance = compose(
    withStyles(styles),
    withColumnMenu,
    withRows,
    withPage,
    withNameSort,
    withKPI,
    withSearchTerm,
    withRoutes,
    withStartWith,
    withFilter,
    withFacetFilter,
    withDateFilter,
    loadComponents
)
export default enhance(Component)