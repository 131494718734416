import { SET_OPEN_ACCOUNTS_LIST, SET_KPI_STATISTICS, SET_DASHBOARD_STATISTICS } from "../actions/reports";


export default (state = {}, action) => {
    switch (action.type) {
        case SET_OPEN_ACCOUNTS_LIST:
            return { ...state, openedAccounts: action.data }
        case SET_KPI_STATISTICS:
            return { ...state, kpiStatistics: action.data }
        case SET_DASHBOARD_STATISTICS: {
            return { ...state, dashboardStatistics: action.data }
        }
        default:
            return state;
    }
};
