import { combineReducers } from 'redux';
import user from './user';
import authentication from './authentication';
import settings from './settings';
import reports from './reports';

const createRootReducer = () =>
    combineReducers({
        user,
        authentication,
        settings,
        reports
    });
export default createRootReducer;