import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    width: 600,
  },
  notchedOutline: {
    borderWidth: "0px",
    borderColor: "transparent !important"
  },
  title: {
    margin: '0 auto',
  },
});
const TextFieldForm = ({
  input: { value, onChange, name, ...restInput },
  meta: { error, touched },
  readOnly,
  classes,
  focus,
  ...rest
}) => {
  return (
    <FormControl style={{ width: '20%' }} >
      <TextField

        {...rest}
        name={name}
        value={value}
        onChange={onChange}
        helperText={touched ? error : undefined}
        margin="dense"
        className={classes.textField}
        InputProps={{

          disableUnderline: true,
          classes: {
            notchedOutline: readOnly && classes.notchedOutline
          }
        }}
      />
    </FormControl>
  )
};


export default withStyles(styles)(TextFieldForm);