import React from 'react';
import { withRouter } from 'react-router-dom';
import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getUserId } from '../../selectors/user';


const Page = ({userId}) => {
    console.log('user',userId)
    return (
        <div />
    )
}


const mapStateToProps = createStructuredSelector({
    userId: getUserId,


})

const loadComponents = lifecycle({
    componentDidMount() {
        const { history, userId } = this.props
        history.push(`/user/${userId}`)
    },

},
);

export const enhance = compose(
    connect(mapStateToProps, null),
    withRouter,
    loadComponents
)
export default enhance(Page);