export const civility = [
    {value: "dr", label: 'Doctor'},
    {value: "ms", label: 'Miss'},
    {value: "mr", label: 'Mister'}];
export const language = [
    {value: "en", label: "English"},
    {value: "fr", label: "French"}
];
export const level = [
    {value: 'LEVEL_1', label: "Level 1"},
    {value: 'LEVEL_2', label: "Level 2"},
    {value: 'LEVEL_3', label: "Level 3"}
];
export const type = [
    {value: "employee", label: "Employee"},
    {value: "temp", label: "Temp"},
    {value: "client", label: "Client"},
    {value: "supplier", label: "Supplier"}
];
export const status = [
    {value: "PENDING", label: "On Hold"},
    {value: "SUSPENDED", label: "Suspended"},
    {value: "ACTIVE", label: "Active"},
    {value: "BLOCKED", label: "Blocked"},
    {value: "CLOSED", label: "Closed"},
    {value: "EXPIRED", label: "Expired"},
    {value: "SATURATED", label: "Saturated"},
]
export const alphabet = [
    {value: "a", label: "A"},
    {value: "b", label: "B"},
    {value: "c", label: "C"},
    {value: "d", label: "D"},
    {value: "e", label: "E"},
    {value: "f", label: "F"},
    {value: "g", label: "G"},
    {value: "h", label: "H"},
    {value: "i", label: "I"},
    {value: "j", label: "J"},
    {value: "k", label: "K"},
    {value: "l", label: "L"},
    {value: "m", label: "M"},
    {value: "n", label: "N"},
    {value: "o", label: "O"},
    {value: "p", label: "P"},
    {value: "q", label: "Q"},
    {value: "r", label: "R"},
    {value: "s", label: "S"},
    {value: "t", label: "T"},
    {value: "u", label: "U"},
    {value: "v", label: "V"},
    {value: "w", label: "W"},
    {value: "x", label: "X"},
    {value: "y", label: "Y"},
    {value: "z", label: "Z"},

]
export const statusToFilter = [
    {value: "", label: "All", type: ''},
    {value: "OPEN", label: "Open", type: "account"},
    {value: "CLOSED", label: "Closed", type: "account"},
    {value: "ACTIVE", label: "Active", type: "convention"},
    {value: "PENDING", label: "On Hold", type: "convention"},
    {value: "BLOCKED", label: "Blocked", type: "convention"},
    {value: "SUSPENDED", label: "Suspended", type: "convention"},
    {value: "EXPIRED", label: "Expired", type: "convention"},
    {value: "SATURATED", label: "Saturated", type: "convention"},
]

export const emailsList = [
    {value: "ack-cvn", label: "Send convention", language: "FR"},
    {value: 'ack-cvn', label: "Send convention", language: "EN"},
    {value: "renew-expired-convention", label: "Renewal-Date", language: "FR"},
    {value: "renew-expired-convention", label: "Renewal-Date", language: "EN"},
    {value: "renew-saturated-convention", label: "Renewal-#", language: "FR"},
    {value: "renew-saturated-convention", label: "Renewal-#", language: "EN"},
]


export const lastActivity = [
    {value: 'under30', label: 'Under 30 days', operator: 'under', days: '30'},
    {value: 'under90', label: 'Under 90 days', operator: 'under', days: '90'},
    {value: 'over90', label: 'Over 90 days', operator: 'over', days: '90'},
    {value: 'none', label: 'None', operator: 'none', days: '0'}
]

export const statusConvention = [
    {value: true, label: 'On'},
    {value: false, label: 'Off'}
]
export const challengeReceptionMode = [
    {value: "APPS", label: 'Apps'},
    {value: "MAIL", label: 'Mail'},
    {value: "SMS", label: 'Sms'},
    {value: "INTEGRATED", label: 'Integrated'}];