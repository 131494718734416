import React from 'react';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter, Link } from 'react-router-dom';
import withRoutes from '../../../hoc/routes';

const styles = theme => ({

    bigIndicator: {
        height: 0,
    },
    
    tabRoot: {
        textTransform: 'initial',
        fontWeight: "bold",
        fontSize: 20,
        // color: "#4d4f5a",
        color: "#7d7d7d",

        '&:hover': {
            color: '#fff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#fff',
        },
        '&:focus': {
            color: '#fff',
        },
    },
    tabSelected: {},

});

const Component = ({ classes, toggleTabs, value, routes, history }) => {
    return (
        <Tabs value={value}
            classes={{ indicator: classes.bigIndicator,}}
            scrolling="on"
            scrollButtons="on"
        >
            <Tab component={Link}
                to={routes.getPathByName('Home')}
                label="Dashboard" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                onClick={() => toggleTabs(0)} />
            <Tab label="Reports" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                onClick={() => { history.push('/reports'); toggleTabs(1) }} />
            <Tab label="Settings" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                onClick={() => { history.push('/settings'); toggleTabs(2) }} />
        </Tabs>
    )
}

const withTabs = withStateHandlers(() => ({ value: 0 }), {
    toggleTabs: () => value => ({ value: value }),
});
const loadComponents = lifecycle({
    componentDidMount() {
        const { toggleTabs, history } = this.props
        if (history.location.pathname === '/settings') {
            toggleTabs(2)
        }
        if (history.location.pathname === '/reports') {
            toggleTabs(1)
        }
    },

},
);
export const enhance = compose(
    withStyles(styles),
    withTabs,
    withRouter,
    withRoutes,
    loadComponents
);
export default enhance(Component);