import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Spacer from '../../../widgets/Spacer';
import Keycloak from '../../../Keycloak'
const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow:'hidden'
    },
    grow: {
        flexGrow: 1,
    },

    button: {
        backgroundColor: '#fff',
        color: 'black',
        fontSize: '16px',
        width: 'auto',
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    typography: {
        color: "#28558a",
        fontWeight: 600
    }
});
const Component = ({ classes, actions, history,clientName }) => {

    return (
        <AppBar style={{ backgroundColor: "#fff" , overflow:'hidden' }} position="static">
            <Toolbar>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={10}>
                        <Grid container justify="flex-end" alignItems="center" > 
                            <Typography  className={classes.typography} variant="button">{clientName}</Typography>
                            <Spacer size="MEDIUM" />
                            <Button className={classes.button} >help</Button>
                            <Spacer size="MEDIUM" />
                            <Button onClick={() => { Keycloak.logout() }} className={classes.button}>sign out</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export const enhance = compose(
    withStyles(styles),
    withRouter
);

export default enhance(Component);