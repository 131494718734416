import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Content from '../../widgets/Content';
import * as userActions from '../../actions/user'
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getUserId, getUser, getUserUpdatedStatus, getSortedFamilies, getUserErrors } from '../../selectors/user';
import { connect } from 'react-redux'
import EditUser from '../../components/EditUser';
import { equals, not } from 'ramda';


const styles = () => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%"
    },
    title: {
        fontWeight: 600,
        color: '#28558a',
        marginLeft: "1%"
    }

})

const Page = ({ actions, families, userId, user, isUserUpdated, userErrors }) => {

    return (
        <Grid container>
            <Content>
                <EditUser isUserUpdated={isUserUpdated} families={families} actions={actions} userId={userId} user={user} userErrors={userErrors} />
            </Content>
        </Grid>
    )
}

const actions = {
    getFamilies: userActions.getFamilies,
    addUser: userActions.addUser,
    getUser: userActions.getUserById,
    updateUser: userActions.UpdateUser,
    toggleUpdatedStatus: userActions.toggleUserUpdatedStatus,
    closeAccount: userActions.closeAccount,
    setUserErrors: userActions.setErrorsUser,
    openAccount: userActions.openAccount

};


const mapStateToProps = createStructuredSelector({
    families: getSortedFamilies,
    userId: getUserId,
    user: getUser,
    isUserUpdated: getUserUpdatedStatus,
    userErrors: getUserErrors

})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, match } = this.props
        actions.getUser(match.params.id)


    },
    componentWillReceiveProps(nextProps) {
        const { user, actions } = this.props
        console.log("user ===",user);
        if (not(equals(nextProps.user, user))) {
            actions.getFamilies(nextProps.user.securityLevel)
        }
    }


},
);
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    loadComponents,
)

export default enhance(Page)