import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import FilterGroup from '../../../widgets/FilterGroup';


const styles = () => ({

});
const accounts = [
    { value: 'all', label: 'All' },
    { value: 'level', label: 'By Level' },
    { value: 'type', label: 'By Type' },
]

const unit = [
    { value: 'failed', label: '% failed logins' },
    { value: 'blocked', label: '% blocked accounts/month' },
    { value: 'conventions', label: '# conventions p.u./month' },
    { value: 'connections', label: '# connections p.u./month' }]

const Component = ({ toggleAccountFilter, accountValue, toggleUnitFilter, unitValue }) => {

    return (
        <Grid container>
            <Grid item xs={10}>
                <FilterGroup action={toggleUnitFilter} value={unitValue} object={unit} title={'KPI'} />
            </Grid>
            <Grid item xs={10}>
                <FilterGroup action={toggleAccountFilter} value={accountValue} object={accounts} title={'Filter'} />
            </Grid>

        </Grid>

    )
}

const enhance = compose(
    withStyles(styles),
   );
export default enhance(Component);