import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { map} from 'ramda';
import { compose, lifecycle } from 'recompose';
import { withStyles, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
 
  formControl: {
    width: "100%",
    "& label span": {
      color: "red"
    }
  },
  notchedOutline: {
    borderColor: "transparent !important"
  },
})
const SelectField = ({ input: { value, name, onChange, ...restInput }, label, object, meta: { error, touched }, classes, actions, readOnly, families, ...rest }) => {
  return (
    <FormControl style={{ width: '90%' }} >
      <TextField
        select={readOnly ? false : true}
        label={label}
        {...rest}
        InputProps={{
          readOnly: readOnly,
          classes: {
            notchedOutline: readOnly && classes.notchedOutline
          }
        }}
        onChange={onChange}
        value={value}
        error={error && touched}
        helperText={touched ? error : undefined}

        className={classes.textField}
        margin="normal"
        variant="outlined"
      >
        {map(element => (
          <MenuItem key={element.value} value={element.value}>
            {element.label}
          </MenuItem>
        ))(object)}
      </TextField>
    </FormControl>

  )
};

const loadComponents = lifecycle({
  componentWillReceiveProps(nextProps) {
    const { input, actions,readOnly } = this.props

    if (readOnly !== true && nextProps.input.value !== input.value) {
      actions.getFamilies(nextProps.input.value)

    }
  }
},
);
export const enhance = compose(withStyles(styles), loadComponents);
export default enhance(SelectField);
