import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import reducers from './reducers';
import './index.css';
import App from './components/App';
import { RoutesContextProvider } from './components/RoutesContext';
import * as serviceWorker from './serviceWorker';
import routes from './routes';
import { IntlProvider } from "react-intl";
import { addLocaleData } from "react-intl";
import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import messages_en from "./utils/messages/en.json";
import messages_fr from "./utils/messages/fr.json";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);

const messages = {
    'fr': messages_fr,
    'en': messages_en
};
let store;
if (process.env.NODE_ENV === "development") {
    store = createStore(
        reducers(),
        composeWithDevTools(
            applyMiddleware(
                thunk,
                createLogger({ collapsed: true }),
            ),
        ),
    );
} else {
    store = createStore(
        reducers(),
        composeWithDevTools(
            applyMiddleware(
                thunk,
            ),
        ),
    );
}
const history = createBrowserHistory();


const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {

        MuiInputBase: {
            input: {
                color: '#5c7086',
                fontSize: 15,
                height: 46
            },
            root: {
                height: 46
            },


        },

        MuiOutlinedInput: {
            root: {
                //height: '55px'
            }
        },

        MuiPaper: {
            elevation2: {
                boxShadow: 'none'
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px 10px 0px 10px'
            }
        },
        MuiFormControl: {
            marginNormal: {

                marginTop: 8,
                marginBottom: 4

            }
        }




    },
});
const ROOT = (
    <IntlProvider locale={'en'} messages={messages['en']}>
        <RoutesContextProvider value={routes}>
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router history={history}>
                        <App />
                    </Router>
                </MuiThemeProvider>
            </Provider>
        </RoutesContextProvider>
    </IntlProvider>
);
ReactDOM.render(ROOT, document.getElementById('root'));
serviceWorker.unregister();
