import { SET_TOKEN, SET_LOGGED_IN, LOGOUT, SET_ERRORS, SET_CLIENT_NAME } from "../actions/authentication";
import { saveAuthenticationState, saveToken, saveDecodedToken } from "../utils/sessionStorage";

export default (state = { isLoggedIn: false }, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, token: action.data }
        case SET_LOGGED_IN: {
            return { ...state, isLoggedIn: action.value }
        }
        case LOGOUT: {
            saveAuthenticationState(null);
            saveToken()
            saveDecodedToken()

            return { ...state, isLoggedIn: undefined }
        }
        case SET_ERRORS: {
            return { ...state, error: action.error }
        }
        case SET_CLIENT_NAME: {
            return { ...state, clientName: action.data }
        }
        default:
            return state;
    }
};
