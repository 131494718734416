import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { compose, lifecycle } from "recompose";
import Grid from '@material-ui/core/Grid';
import { find, findIndex, propEq, remove, map } from 'ramda';
import { MenuItem } from "@material-ui/core";
import { language } from "../../../utils/select";
const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    headContainer: {
        marginLeft: '3%'
    }

});
const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: 'inherit'
    },
    body: {

        color: '#5c7086',
        fontSize: 15,

    },

}))(TableCell);
const EditableTableCell = ({ row, fieldName, min, max, type, onCellValueChange, rowsChanges, systemErrors }) => {
    const handleTextFieldChange = e => {
        onCellValueChange({
            fieldValue: e.target.value,
            fieldName: fieldName,
            min: min,
            max: max,
        });
    };
    const itExist = find(propEq('id', row.id))(rowsChanges)

    return (
        <TableCell >
            <Grid container >
                {type === 'java.lang.Integer' ? 
                <TextField
                    onChange={handleTextFieldChange}
                    id={fieldName}
                    defaultValue={row[fieldName]}
                    InputProps={{
                        inputProps: {
                            min: min,
                            max: max
                        }
                    }}
                    style={{ width: "100px" }}
                    margin="normal"
                    type="number"
                /> 
                :
                 <TextField
                    select={row.code === 'client_language' ? true : false}
                    onChange={handleTextFieldChange}
                    id={fieldName}
                    value={itExist ? itExist.value : row[fieldName]}
                    style={{ width: "100px" }}
                    margin="normal"
                >
                        {map(element => (
                            <MenuItem key={element.value} value={element.value}>
                                {element.value}
                            </MenuItem>
                        ))(language)}
                    </TextField>}
            </Grid>
        </TableCell>
    );
};

const Component = ({ classes, toggleTextField, rowsChanges, editMode, systemeSettings }) => {

    const handleTextFieldChange = (rowIndex, id, change) => {
        const itExist = find(propEq('id', id))(rowsChanges)
        if (!itExist) {
            toggleTextField([
                ...rowsChanges,
                { ...rowsChanges[rowIndex], [change.fieldName]: change.fieldValue, id: id }
            ])
        }
        else {
            const index = findIndex(propEq('id', id))(rowsChanges);
            let newRowsChanges = remove(index, 1, rowsChanges)
            toggleTextField([
                ...newRowsChanges,
                { ...newRowsChanges[rowIndex], [change.fieldName]: change.fieldValue, id: id }
            ])

        }

    }



    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell >
                            <Grid container className={classes.headContainer}>
                                Item
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell >
                            <Grid container className={classes.headContainer}>
                                Value
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell >
                            <Grid container className={classes.headContainer}>
                                Unit
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell >
                            <Grid container className={classes.headContainer}>
                                Note
                             </Grid>
                        </CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {systemeSettings && systemeSettings.map((row, index) => {
                        return (
                            <TableRow key={row.id}>
                                <CustomTableCell  >{row.libelle}</CustomTableCell>
                                {editMode ?
                                    <EditableTableCell

                                        row={row}
                                        fieldName="value"
                                        min={row.min}
                                        max={row.max}
                                        type={row.dataType}
                                        rowsChanges={rowsChanges}
                                        onCellValueChange={(event) => handleTextFieldChange(index, row.id, event)}
                                    /> :
                                    <CustomTableCell   >{row.value}</CustomTableCell>}
                                <CustomTableCell   >{row.unit}</CustomTableCell>
                                <CustomTableCell  >{row.min && `min:${row.min}`}{row.max && `, max:${row.max}`}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}


const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.getSystemeSettings()

    },

},
);
export const enhance = compose(
    withStyles(styles),
    loadComponents)
export default enhance(Component);
