import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CollumnMenu from '../../../widgets/CollumnMenu';
import { compose } from 'recompose';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import { withStyles, Typography } from '@material-ui/core';
import { level, type } from '../../../utils/select'
import { getLabel, fullname } from '../../../utils/models';
import { formatDateWithTime } from '../../../utils/date';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActionsWrapped from '../../../widgets/TablePagination';
import { withRouter } from 'react-router-dom'

const styles = theme => ({
    root: {
        minWidth: 900,
        marginTop: theme.spacing.unit * 3,
    },
    table: {

        minWidth: 900,
        border: '1px solid rgba(224, 224, 224, 1)',

    },

    tabRoot: {
        textTransform: 'initial',
        fontSize: 15,
        color: "black",
        marginLeft: "2px",


        '&:hover': {
            color: 'red',
            opacity: 1,
        },
        '&$tabSelected': {
            color: 'red',
        },
        '&:focus': {
            color: 'red',
        },
    },
    tabSelected: {},

})

const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: 'inherit'


    },

}))(TableCell);


const Component = ({ toggleMenu, menu, anchor, history, classes, users, rowsPerPage, toggleRows, page, togglePage, pageInformation,
    actions, toggleNameSort, nameSort, toggleFilter, searchLevel, searchType, toggleDateFilter }) => {

    return (
        <Paper className={classes.root} >
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell>
                            <Grid container alignItems="center" >
                                <Grid item xs={11} >
                                    <Grid container style={{ marginLeft: '3%' }} direction="column">
                                        <Grid item> Name</Grid>
                                        <Grid item>& HIA ID</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container justify="flex-end">
                                        {nameSort === '' || nameSort === 'desc' ?
                                            <ExpandMore onClick={() => toggleNameSort('asc')} /> :
                                            <ExpandLess onClick={() => toggleNameSort('desc')} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CustomTableCell>

                        <CustomTableCell >
                            <Grid container alignItems="center" >
                                <Grid item xs={11} >
                                    <Grid container style={{ marginLeft: '3%' }} direction="column">
                                        Type
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container justify="flex-end">
                                        <ArrowDropDown
                                            onClick={(event) => toggleMenu(event.currentTarget, 'type')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell >
                            <Grid container alignItems="center" >
                                <Grid item xs={11} >
                                    <Grid container style={{ marginLeft: '3%' }} direction="column">
                                        Level
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container justify="flex-end">
                                        <ArrowDropDown
                                            onClick={(event) => toggleMenu(event.currentTarget, 'level')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell >
                            <Grid container alignItems="center" >
                                <Grid item xs={11} >
                                    <Grid container style={{ marginLeft: '3%' }} direction="column">
                                        Last activity
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid container justify="flex-end">
                                        <ArrowDropDown
                                            onClick={(event) => toggleMenu(event.currentTarget, 'lastActivity')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell>
                            <Grid item xs={11} >
                                <Grid container style={{ marginLeft: '3%' }} direction="column">
                                    <Grid item> Email</Grid>
                                    <Grid item>& phone</Grid>
                                </Grid>
                            </Grid>
                        </CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users && users.map(user => (
                        <TableRow style={{ cursor: 'pointer' }} onClick={() => history.push(`/user/${user.id}`)} key={user.id}>
                            <TableCell width="35%" style={{ minWidth: 300 }} >
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography style={{ color: '#28558a', fontWeight: 600 }}>
                                            {fullname(user)}
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography>
                                            {user.hiaId}
                                        </Typography>
                                    </Grid>
                                </Grid></TableCell>
                            <TableCell width="15%" style={{ minWidth: 100 }} >
                                <Typography style={{ color: '#28558a' }}>
                                    {getLabel(user.type, type)}
                                </Typography>
                            </TableCell>
                            <TableCell width="15%" style={{ minWidth: 100 }}>
                                <Typography style={{ color: '#28558a' }}>
                                    {getLabel(user.securityLevel, level)}
                                </Typography>
                            </TableCell>
                            <TableCell width="15%" style={{ minWidth: 100 }} >
                                <Typography style={{ color: '#28558a' }}>
                                    {user.lastActivityDate !== null ? formatDateWithTime(user.lastActivityDate) : undefined}
                                </Typography>
                            </TableCell>
                            <TableCell width="30%" style={{ minWidth: 300 }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography style={{ color: '#28558a' }}>
                                            {user.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Typography>
                                            {user.phone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {users && pageInformation && <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            colSpan={10}
                            count={pageInformation.totalElements}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={toggleRows}
                            page={page}
                            props={pageInformation}
                            nextIconButtonProps={actions}
                            onChangePage={togglePage}
                            ActionsComponent={TablePaginationActionsWrapped}

                        />}
                    </TableRow>
                </TableFooter>
            </Table>
            <CollumnMenu anchorEl={anchor} toggleMenu={toggleMenu} menu={menu} togglePage={togglePage} toggleFilter={toggleFilter}
                searchType={searchType} searchLevel={searchLevel} toggleDateFilter={toggleDateFilter} />
        </Paper>
    )
}



export const enhance = compose(
    withStyles(styles),
    withRouter,
);
export default enhance(Component)