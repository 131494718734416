import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem, Checkbox, TextField, withStyles } from '@material-ui/core';
import { compose} from 'ramda';
import { getFamilyName, sortFamiliesSelected } from '../../utils/models';
const styles = (theme) => ({
  
    notchedOutline: {
        borderWidth: "0px",
        borderColor: "transparent !important"
    },
})

const MultiSelectField = ({ input: { value, name, onChange, ...restInput }, selected, toggleCheckBox, label, elements, meta, families, readOnly, classes, ...rest }) => {

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        toggleCheckBox(newSelected)

    }
    const handleSelectAllClick = (event, data, ) => {
        let newSelected = [];
        if (event.target.checked) {
            newSelected = data.map(n => n.id);
            toggleCheckBox(newSelected);
            return;
        }

        toggleCheckBox(newSelected);
    };
    const isCheckSelected = (id, selected) => selected.indexOf(id) !== -1;
    return (
        <FormControl style={{ width: '90%' }} >
            <TextField
                id="outlined-select-currency"
                select={readOnly ? false : true}
                label={label}
                {...rest}
                InputProps={{
                    readOnly: readOnly,
                    classes: {
                        notchedOutline: readOnly && classes.notchedOutline
                    }
                }}
                value={families&&readOnly&&selected.length === families.length?['All']:[...selected && sortFamiliesSelected(selected)]}
                error={meta.error && meta.touched}
                helperText={meta.touched ? meta.error : undefined}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                SelectProps={ {

                    multiple: true,
                    renderValue: selected =>families && selected.length === families.length ? 'All' :families&& getFamilyName(families, selected).join(", ")

                }}
            >
                <MenuItem onChange={event => handleSelectAllClick(event, families)} >
                    <em>
                        <Checkbox checked={families && families.length === selected.length ? true : false} />
                        Select All
                    </em>
                </MenuItem>
                {families && families.map(element => {
                    const isSelected = isCheckSelected(element.id, selected);
                    return (

                        <MenuItem key={element.id} value={element.name} >
                            <Checkbox checked={isSelected} onChange={event => handleClick(event, element.id, element.name)} />
                            {element.name}
                        </MenuItem>
                    )
                })}
            </TextField>
        </FormControl>
    )
};


export const enhance = compose(withStyles(styles))
export default enhance(MultiSelectField);
