import React from 'react'
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        //paddingTop: theme.spacing.unit * 2,
        //paddingBottom: theme.spacing.unit * 2,
        backgrounColor: '#bfbfbf',
        marginTop: theme.spacing.unit * 2
    },
    typography: {
        color: 'black',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
})

const Component = ({ classes, action, object, value, title }) => {
    return (
        <Paper className={classes.root} elevation={1}>
            <Grid container >
                <Grid item xs={12}>
                    <Typography className={classes.typography}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>

                    <FormControl component="fieldset" className={classes.formControl}>

                        <RadioGroup

                            className={classes.group}
                            value={value}
                            onChange={(event) => action(event.target.value)}
                        >
                            {
                                object.map(item => <FormControlLabel
                                    key={item.value}
                                    value={item.value}
                                    control={<Radio color="primary" />}
                                    label={item.label}
                                />)
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const enhance = compose(withStyles(styles))
export default enhance(Component)