import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { compose, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import { level, statusConvention } from '../utils/select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import { find, findIndex, propEq, remove } from 'ramda';

const styles = () => ({
    menu: {
        backgroundColor: "#bfbfbf",
        width: 100,
        justifyContent: 'center',
    },
    selectedMenu: {
        backgroundColor: "white",
        width: 100
    }
})
const Component = ({ anchorEl, toggleMenu, classes, menu, cursor, handleKey, toggleFilter,
    searchLevel, searchType, }) => {

    const handleClick = (id, type) => {
        const typeExist = find(propEq('type', type))(cursor)
        if (typeExist === undefined) {
            const newSelected = cursor.concat({ 'id': id, 'type': type });
            handleKey(newSelected)
        }
        else {
            const index = findIndex(propEq('type', type))(cursor);
            let newSelected = remove(index, 1, cursor)
            if (typeExist.id !== id) {
                newSelected = newSelected.concat({ 'id': id, 'type': type });

            }
            handleKey(newSelected)
        }
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}

            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => toggleMenu()}

        >

            {
                menu === 'level' ?
                    level.map((element, i) => {
                        const inTable = find(element => element.id === i && element.type === menu)(cursor)
                        return (<MenuItem style={{ zIndex: 20 }} key={element.value} className={inTable ? classes.selectedMenu : classes.menu}
                            onClick={() => {
                                handleClick(i, menu);
                                toggleFilter(inTable ? '' : element.value, searchType);
                                toggleMenu();
                            }}
                        >
                            <Grid item xs={12}>
                                <Grid container alignItems="center" >
                                    <Grid item xs={9}>
                                        <Grid container justify="flex-start">
                                            <Typography   >
                                                {element.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container justify='flex-end' >
                                            {inTable &&
                                                <Close />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MenuItem>)
                    })
                    :
                    menu === 'status' ?
                        statusConvention.map((element, i) => {
                            const inTable = find(element => element.id === i && element.type === menu)(cursor)
                            return (<MenuItem key={element.value} className={inTable ? classes.selectedMenu : classes.menu}
                                onClick={() => {
                                    handleClick(i, menu);
                                    toggleFilter(searchLevel, inTable ? '' : element.value);
                                    toggleMenu();
                                }}
                            >
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" >
                                        <Grid item xs={9}>
                                            <Grid container justify="flex-start">
                                                <Typography   >
                                                    {element.label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container justify='flex-end' >
                                                {inTable &&
                                                    <Close />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MenuItem>)
                        })
                        : <React.Fragment>
                            <MenuItem className={classes.menu}  ></MenuItem>
                            <MenuItem className={classes.menu}  ></MenuItem>
                            <MenuItem className={classes.menu} ></MenuItem>
                        </React.Fragment>}
        </Popover>
    )
}

const withKey = withStateHandlers(() => ({ cursor: [], }), {
    handleKey: () => (value1) => ({ cursor: value1 }),
})

export const enhance = compose(
    withStyles(styles),
    withKey)
export default enhance(Component)