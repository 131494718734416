import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

const styles = () => ({
    root: {
        marginTop: '1%',
    },

    typography: {
        fontWeight: 600,
        color: '#4A6D96',
        fontSize: 16
    }
})
const Component = ({ children, title, classes }) => {

    return (
        <Grid container className={classes.root}>
            <Grid item xs={10}>
                <Grid container style={{
                    border: "1px solid #4a7ebb",
                    borderRadius: "25px",
                    marginTop: '1%'
                }}>
                    <Grid container style={{marginTop:'1%',marginBottom:'1%'}} alignItems="center" direction="row">
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);