import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Content from '../../widgets/Content';
import SaveUser from '../../components/SaveUser';
import * as userActions from '../../actions/user'
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getUserId, getSortedFamilies, getUserErrors } from '../../selectors/user';
import { connect } from 'react-redux';
import { getLanguageSettings } from '../../selectors/settings';

const styles = () => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%"
    },
    title: {
        fontWeight: 600,
        color: '#28558a',
        marginLeft: "1%"
    }

})

const Page = ({ actions, families, user,userErrors,clientLanguage }) => {
    return (
        <Grid container>
            <Content>
                <SaveUser families={families} actions={actions} user={user} userErrors={userErrors} clientLanguage={clientLanguage} />
            </Content>
        </Grid>
    )
}

const actions = {
    getFamilies: userActions.getFamilies,
    addUser: userActions.addUser,
    getUser: userActions.getUserById,
    setUserErrors:userActions.setErrorsUser
};


const mapStateToProps = createStructuredSelector({
    families: getSortedFamilies,
    user: getUserId,
    userErrors: getUserErrors,
    clientLanguage:getLanguageSettings

})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.getFamilies('LEVEL_1')
    },

},
);
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    loadComponents,
)

export default enhance(Page)