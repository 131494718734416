import { path, filter, compose, find, prop, reduce, toPairs } from 'ramda';


import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser'
import Redirect from './pages/Redirect';
import Authentication from './pages/Authentication';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Reports from './pages/Reports';

export const routes = {
    Authentication: {
        path: "/signIn",
        exact: true,
        auth: true,
        component: Authentication,

    },
    AddUser: {
        path: "/addUser",
        exact: true,
        component: AddUser,
        auth: true,



    },
    EditUser: {
        path: "/user/:id",
        exact: true,
        component: EditUser,
        auth: true,


    },
    Redirect: {
        path: "/redirect",
        exact: true,
        component: Redirect,
        auth: true,

    },
    Home: {
        path: "/home",
        exact: true,
        auth: true,
        component: Users,
        default: true,

    },
    Settings: {
        path: "/settings",
        exact: true,
        auth: true,
        component: Settings,

    },
    Reports: {
        path: "/reports",
        exact: true,
        auth: true,
        component: Reports,

    },

};
const exportedRoutes = compose(
    reduce((acc, [name, r]) => [...acc, { ...r, name }], []),
    toPairs,
    filter(prop('path')),
)(routes);

export const getDefaultRoute = () => find(prop('default'), exportedRoutes);
export const getRouteByName = name => routes[name];
export const getRouteByPath = path => find(r => r.path === path, exportedRoutes);
export const getPathByName = (name, param) => {
    const path = prop('path', getRouteByName(name));
    return param ? `${path.replace(':id', param)}` : path;
};
export const getRouteProp = prop => name => path([name, prop], routes);
export const getRouteRoles = getRouteProp('roles');
export const getRouteAuthProps = name => ({ roles: getRouteRoles(name) });
export const isAuthRequired = route => route && (route.auth || route.roles);

export default {
    getRoutes: () => exportedRoutes,
    getDefaultRoute,
    getRouteByName,
    getPathByName,
    getRouteByPath,
    getRouteProp,
    getRouteRoles,
    getRouteAuthProps,
    isAuthRequired,
};
