import React from 'react'
import { Grid, withStyles, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Field } from 'react-final-form';
import EditorMail from '../../../forms/EditorMail'
import TableTextField from '../../../forms/TableTextField';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        marginTop: '3%',

    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        color: '#28558a'
    },
    subTitle: {
        marginTop: '2%',
        fontSize: 14,
        fontWeight: 'bold'
    },
    container: {
        marginTop: '2%',
        border: '1px solid black'
    },
    text: {
        marginLeft: '2%',
        height: 350,
        color: '#5c7086'
    }
});

const Component = ({ classes, editMode, Email }) => {

    return (

        <Grid container>
            <Grid item xs={12}>
                <Grid container className={classes.root} direction="column">
                    <Typography className={classes.title}>
                        Subject
                    </Typography>
                    {!editMode ? <Typography className={classes.subTitle} >
                        {Email && Email.subject}
                    </Typography> :
                        <Paper style={{ border: '1px solid black' }}>
                            <Field
                                name='subject'
                                type="text"
                                component={TableTextField}
                            />
                        </Paper>}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.root} direction="column">
                    <Typography className={classes.title}>
                        Text
                                </Typography>
                    {!editMode ?
                        <Typography dangerouslySetInnerHTML={{ __html: Email && Email.template }} />
                        :
                        <Field
                            name="template"
                            component={EditorMail}
                            
                        />}
                </Grid>
            </Grid>
        </Grid>

    )
}

export default withStyles(styles)(Component)


