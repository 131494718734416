import React from "react";
import { compose, withStateHandlers, lifecycle } from "recompose";
import { withStyles, Typography } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { emailsList } from '../../../utils/select';
import Grid from '@material-ui/core/Grid';
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    active: {
        backgroundColor: 'rgba(45,56,130,1)',
        '&:hover': {
            backgroundColor: 'rgba(45,56,130,1)'
        }
    },
    typhoActived: {
        color: 'white',
        fontWeight: 600
    },
    typhoNotActived: {
        color: 'rgba(45,56,130,1)',
    }

})



const Component = ({ classes, cursor, handleKey, handleEmail,editMode }) => {
    const handleKeyDown = (e) => {
        let newValue = 0
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38 && cursor > 0) {
            newValue = cursor - 1
        } else if (e.keyCode === 40 && cursor < emailsList.length - 1) {
            newValue = cursor + 1
        }
        handleKey(newValue)
    }
    return (

        <List component="nav" >
            {emailsList.map((item, i) => <ListItem
                disabled={editMode}
                button
                className={cursor === i ? classes.active : null}
                onClick={() => { handleKey(i); }}
                onKeyDown={(event) => { handleKeyDown(event); }}
                key={i}
            >

                <Grid item xs={12}>
                    <Grid container >
                        <Grid item xs={11}>
                            <Grid container justify="flex-start">
                                <Typography className={cursor === i ? classes.typhoActived : classes.typhoNotActived} >
                                    {item.label}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Grid container >
                                <Typography className={cursor === i ? classes.typhoActived : classes.typhoNotActived} >
                                    {item.language}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </ListItem>
            )}

        </List>

    )
}

const withKey = withStateHandlers(() => ({ cursor: 0 }), {
    handleKey: () => (value) => ({ cursor: value }),
})
const loadComponents = lifecycle({
    componentWillReceiveProps(nextProps) {
        const { cursor, handleEmail } = this.props
        if (nextProps.cursor !== cursor) {
            handleEmail(emailsList[nextProps.cursor].value,emailsList[nextProps.cursor].language)

        }
    },

},
);
export const enhance = compose(
    withStyles(styles),
    withKey,
    loadComponents
);
export default enhance(Component);