import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Content from '../../widgets/Content'
import Settings from '../../components/Settings';
import * as settingsAction from '../../actions/settings'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getSysSettings, getEditMode, getEmailSettings, getConvSettings, getSystemError } from '../../selectors/settings';


const styles = () => ({

})

const Page = ({ actions, systemeSettings, conventionSettings, emailSettings, editMode,systemErrors }) => {
    return (
        <Grid container>

            <Content>
                <Settings actions={actions} systemeSettings={systemeSettings} conventionSettings={conventionSettings} emailSettings={emailSettings} editMode={editMode} systemErrors={systemErrors} />
            </Content>
        </Grid>

    )

}

const actions = {
    getSystemeSettings: settingsAction.getSystemeSettings,
    getConventionSettings: settingsAction.getConventionSettings,
    UpdateSystemeSettings: settingsAction.UpdateSystemeSettings,
    toggleEditMode: settingsAction.toggleEditMode,
    getEmailSettings: settingsAction.getEmailSettings,
    UpdateConventionSettings: settingsAction.UpdateConventionSettings,
    UpdateEmailSettings: settingsAction.UpdateEmailSettings,
    setSystemErrors:settingsAction.setSystemErrors
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    systemeSettings: getSysSettings,
    conventionSettings: getConvSettings,
    editMode: getEditMode,
    emailSettings: getEmailSettings,
    systemErrors:getSystemError

})
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps))
export default enhance(Page)