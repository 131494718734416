import React from 'react'
import { lifecycle, compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Content from '../../widgets/Content';
import Users from '../../components/Users';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user'
import { getAllUsers, getUserPages, getCount } from '../../selectors/user';
import { createStructuredSelector } from 'reselect';
import { getDashboardStats } from '../../selectors/reports';
import * as reportActions from '../../actions/reports';

const styles = () => ({

})

const Page = ({ users, pageInformation, actions, count, dashboardStats,clientName }) => {

    return (
        <Grid container>
            <Content>
                <Users users={users} pageInformation={pageInformation} actions={actions}
                    count={count} dashboardStats={dashboardStats}  />
            </Content>
        </Grid>
    )
}


const actions = {
    getUsers: userActions.loadUsers,
    searchUser: userActions.searchUser,
    getCounts: userActions.getCounts,
    getUsersFiltredByStatus: userActions.getUsersFiltredByStatus,
    getDashboardStatistics: reportActions.getDashboardStatistics
};


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    users: getAllUsers,
    pageInformation: getUserPages,
    count: getCount,
    dashboardStats: getDashboardStats,

})
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.getDashboardStatistics()
    },

},
);
export const enhance = compose(withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    loadComponents,
)
export default enhance(Page)