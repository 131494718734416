import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';

const Styles = {
  title: {
    fontWeight: 600,
    color: 'rgba(45,56,130,1)',
  }
};

export const Title = ({ text, classes }) => (
  <Typography variant="h6" className={classes.title}>
    {text}
  </Typography>
);


const enhance = compose(withStyles(Styles));
export default enhance(Title);
