import { SET_FAMILIES, SET_ID_USER, SET_USER_BY_ID, TOGGLE_USER_UPDATED_STATUS, SET_USERS, SET_ACCOUNTS_CONVENTIONS_COUNT, SET_USER_ERRORS } from "../actions/user";
import { LOGOUT } from "../actions/authentication";


export default (state = { isLoggedIn: false, }, action) => {
    switch (action.type) {
        case SET_FAMILIES:
            return { ...state, families: action.data }
        case SET_ID_USER: {
            return { ...state, idUser: action.id }
        }
        case TOGGLE_USER_UPDATED_STATUS: {
            return {
                ...state, isUserUpdated: action.status
            }
        }
        case SET_USER_BY_ID: {
            return { ...state, user: action.data }
        }
        case SET_USERS: {
            return { ...state, usersInformations: action.data }
        }

        case SET_ACCOUNTS_CONVENTIONS_COUNT: {
            return { ...state, accountsCount: action.data }
        }

        case LOGOUT: {
            return { ...state, usersInformations: undefined }
        }
        case SET_USER_ERRORS: {
            return { ...state, userErrors: action.data }
        }
        
        default:
            return state;
    }
};
