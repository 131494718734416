import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import ConventionsFilter from './ConventionStatusFilter';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        width: '80%',
        minWidth:200,
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        backgroundColor: '#ececec',

    },
    body: {
        fontSize: 18,
        fontWeight: 'bold',
        marginLeft: '2%'
    }
});

const Component = ({ classes, actions, count,toggleFacetFilter }) => {

    return (
        <Paper className={classes.root} elevation={1}>
            <Grid item xs={12}>
                <Grid container style={{ marginTop: '3%' }}>
                    <Typography className={classes.body}>Filters</Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: '5%' }} >
                <Grid item xs={12}>
                    <ConventionsFilter actions={actions} count={count} toggleFacetFilter={toggleFacetFilter} />
                </Grid>
            </Grid>
        </Paper>
    )
}

export const enhance = compose(withStyles(styles))
export default enhance(Component)

