import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getDateMonth, getDateYear, getSubYear, formatDate } from '../../../utils/date';

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    table: {
        width: 700,
    },
});



const CustomTableCell = withStyles(() => ({
    head: {
        fontWeight: 600,
        color: 'black',
        fontSize: 13
    },
    body: {
        fontWeight: 600,
        color: 'black',
    },

}))(TableCell);
const Component = ({ classes,openedAccounts  }) => {
    return (
        <Paper className={classes.openedAccountsroot}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>

                        <CustomTableCell >Total</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {openedAccounts && openedAccounts.map((row, index) => (
                        <TableRow key={index}>
                            <CustomTableCell component="th" scope="row">
                                {getDateMonth(row.date)}{" '"}{getDateYear(row.date)}
                            </CustomTableCell>
                            <TableCell>
                                {row.count}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>)
}
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.getOpenedAccounts(getSubYear(new Date(),11), formatDate(new Date()))
    },

},
);
const enhance = compose(
    withStyles(styles),
    loadComponents)
export default enhance(Component)