import React from 'react';
import { compose } from 'recompose';
import { withStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const styles = (theme) => ({
    container: {
        marginBottom: theme.spacing.unit * 2
    },
    typography: {
        marginLeft: '10%',
        fontSize: '18px',
        color: '#8c8383eb',
        fontWeight: 'bold'
    }
})
const Component = ({ classes, dashboardStats }) => {

    const options = {
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    display: false

                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    padding: 0,
                    display: false
                },
                gridLines: {
                    drawTicks: false,
                    drawBorder: true,
                    display: false

                }
            }]
        },
        plugins: {
            datalabels: {
                display: false,
                color: 'black',
            }
        }
    };

    const data1 = (data) => ({
        labels: data ? data.statistics.map(t => t.date) : [],
        datasets: [{
            backgroundColor: 'transparent',
            borderColor: '#28558a',
            data: data ? data.statistics.map(t => t.count) : [],
            pointRadius: 1
        }]
    })


    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" className={classes.container} >
                <Grid item xs={10}>
                    <Grid container justify="flex-start">
                        <Grid item xs={3} />
                        <Grid item xs={9}>
                            {dashboardStats && <Grid container justify="flex-start" alignItems="center">

                                {dashboardStats['active'] && <Grid item xs={4}>
                                    <Paper style={{ width: '95%', border: '1px solid black' }}>
                                        <Grid container style={{ marginTop: '5%' }}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography}>
                                                    Active users
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography} >
                                                    {dashboardStats['active'].avg.toFixed(3)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{ marginLeft: '10%', fontSize: '14px', color: 'rgba(140, 131, 131, 0.56)', fontWeight: 'bold' }}>
                                                    past 30 days
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ marginTop: '20px' }}>
                                                    < Line
                                                        data={data1(dashboardStats['active'])}
                                                        options={options}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>}
                                {dashboardStats['connection'] && <Grid item xs={4}>
                                    <Paper style={{ width: '90%', border: '1px solid black' }}>
                                        <Grid container style={{ marginTop: '5%' }}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography}>
                                                    Connections per day
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography} >
                                                    {(dashboardStats['connection'].avg)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{ marginLeft: '10%', fontSize: '14px', color: 'rgba(140, 131, 131, 0.56)', fontWeight: 'bold' }}>
                                                    past 30 days
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ marginTop: '20px' }}>

                                                    < Line
                                                        data={data1(dashboardStats['connection'])}
                                                        options={options}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                }                {dashboardStats['failed'] && <Grid item xs={4}>
                                    <Paper style={{ width: '90%', border: '1px solid black' }}>
                                        <Grid container style={{ marginTop: '5%' }}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography}>
                                                    Failed logins
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.typography} >
                                                    {Math.round(dashboardStats['failed'].avg)} %
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{ marginLeft: '10%', fontSize: '14px', color: 'rgba(140, 131, 131, 0.56)', fontWeight: 'bold' }}>
                                                    past 30 days
                                        </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ marginTop: "20px" }}>
                                                    < Line
                                                        data={data1(dashboardStats['failed'])}
                                                        options={options}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>}
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
}

export const enhance = compose(withStyles(styles))
export default enhance(Component)