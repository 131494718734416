import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import React from 'react'

const EditorMail = ({
    input: { value, onChange, name, ...restInput },
    meta: { error, touched },
   
    classes,
  
    ...rest
  }) => {
    const toolbarOptions = [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],         // custom dropdown

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
       
      
               [{ 'list': 'ordered'}, { 'list': 'bullet' }],
               [{ 'align': [] }],
       
        //[{ 'direction': 'rtl' }],                         // text direction
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        ['link', 'image', 'video'],
        ['clean']                                         // remove formatting button
      ];
      
    
    return (
        <ReactQuill
  
          {...rest}
          name={name}
          value={value}
          onChange={onChange}
          modules= {{
            clipboard: {
              matchVisual: false
            },
            toolbar: toolbarOptions}
          }
         
          
         
         
        
        />
    )
  };
  
  
  export default (EditorMail);