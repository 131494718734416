import React, {useCallback, useEffect, useState} from 'react';
import Header from './Header';
import FirstHeader from './FirstHeader';
import { compose, map } from 'ramda';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LoadingSpin from "react-loading-spin";
import { isInRoleBis } from '../../utils/models';

import withRoutes from '../../hoc/routes';
import Helmet from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { loadAuthenticationState } from '../../utils/sessionStorage';
import { getIsLoggedInStatus, getClientName } from '../../selectors/authentication';
import * as authenticationActions from '../../actions/authentication';
import { bindActionCreators } from 'redux';
import Auth from '../Auth';
import keycloakk from '../../Keycloak'
import { lifecycle } from 'recompose';

const makeAuthRoute = ({ isAuthRequired }, route) => props => {
    if (isAuthRequired(route)) {
        return (
            <Auth route={route}>
                <route.component {...props} />
            </Auth>
        );
    }
    return <route.component {...props} />;
};

const defaultRoute = routes => {
    const { getDefaultRoute } = routes;
    const route = getDefaultRoute();
    if (route) return <Redirect to={route.path} />;
};

const Component = ({ routes, isLoggedIn, actions,clientName }) => {
    const [isAutenticated, setIsAutenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(keycloakk);
  const loadConfig = useCallback(() => {
    keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
      setKeycloak(keycloak);
      setIsAutenticated(authenticated);
    });
  }, [keycloak]);
  useEffect(() => {
    loadConfig();
  }, [loadConfig]);
  if (keycloak) {
    console.log(keycloak)    
    if (isAutenticated){
        if(isInRoleBis('HIA_ADMIN_CLIENT', keycloak.realmAccess.roles) || isInRoleBis('HIA_SUPER_ADMIN_CLIENT', keycloak.realmAccess.roles))
        return (
            <Grid style={{ position: 'relative', width: '100%', minWidth: '1300px', overflow: "hidden" }}>
                <Helmet bodyAttributes={{ style: ' font-family: Heebo ; background-color: #fff' }} />
                <Grid item xs={12}>
                    <Grid container >
                       
                            <FirstHeader actions={actions} clientName={clientName}/>
                        
                        <Header isLoggedIn={isLoggedIn} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container   >
                        <Switch>
                            {map(
                                route => (
                                    <Route key={route.path} exact={route.exact} path={route.path} render={makeAuthRoute(routes, route)} />
                                ),
                                routes.getRoutes(),
                            )}
                            {defaultRoute(routes)}
                        </Switch>
                    </Grid>
                </Grid>
    
            </Grid>
    
        )
        else
        keycloak.logout()
    }
    
    else return <div style={{
        position: "absolute",
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px',
    width: '100px',
    height: '100px',
    }}>
        <LoadingSpin
            duration="2s"
            width="15px"
            timingFunction="ease-in-out"
            direction="alternate"
            size="200px"
            primaryColor="blue"
            secondaryColor="white"
            numberOfRotationsInAnimation={2}
        />
    </div>;}
}
const mapStateToProps = createStructuredSelector({
    isLoggedIn: getIsLoggedInStatus,
    clientName:getClientName
})
const actions = {
    logout: authenticationActions.logout,
    getClientName:authenticationActions.getClientName
};
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions } = this.props
       // actions.getClientName()
    },

},
);
export const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withRoutes,
    loadComponents )
export default enhance(Component);
