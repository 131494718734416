import { path, sortWith, ascend, prop, find, propEq, compose } from 'ramda';
import { createSelector } from 'reselect';


export const getSysSettings = path(['settings', 'systemeSettings']);
export const getConvSettings = path(['settings', 'conventionSettings']);
export const getEmailSettings = path(['settings', 'emailSettings'])
export const getEditMode = path(['settings', 'editMode']);
export const getSortedFamily = createSelector(getConvSettings, families => families && sortWith([
    ascend(prop('name')),
])(families))
export const getLanguageSettings = createSelector(getSysSettings, settings => {
    if (settings) {
        const test = compose(find(propEq('code', 'client_language')))(settings)
        if (test) {
            return test.value
        }
    }
})
export const getSystemError = path(['settings', 'systemError'])