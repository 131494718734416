import { path, ascend, sortWith, prop } from 'ramda';
import { createSelector } from 'reselect';

export const getFamilies = path(['user', 'families']);
export const getUserId = path(['user', 'idUser']);
export const getUser = path(['user', 'user']);
export const getUserUpdatedStatus = path(['user', 'isUserUpdated'])

export const getSortedFamilies = createSelector(getFamilies, families => families && sortWith([
    ascend(prop('name'))
])(families))

export const getAllUsers = path(['user', 'usersInformations', '_embedded', 'accountDTOList']);
export const getUserPages = path(['user', 'usersInformations', 'page']);
export const getDateFrom = path(['user', 'dateFrom']);
export const getDateTo = path(['user', 'dateTo']);
export const getCount = path(['user', 'accountsCount']);
export const getWithoutActivity = path(['user', 'withoutActivity']);
export const getUserErrors = path(['user', 'userErrors']);
